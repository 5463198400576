var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$route.path !== '/login' &&
      _vm.$route.path !== '/detalle-proveedor' &&
      _vm.$route.path !== '/detalle-cliente' &&
      _vm.$route.path !== '/detalle-familia' &&
      _vm.$route.path !== '/detalle-referencia' &&
      _vm.$route.path !== '/detalle-pedido' &&
      _vm.isDataReady
  )?_c('div',[_c('sidenav',{class:_vm.curClasses,attrs:{"orientation":_vm.orientation}},[_c('div',{staticClass:"sidenav-inner w-100",class:{ 'py-1': _vm.orientation !== 'horizontal' },attrs:{"active":_vm.isMenuActive('/'),"open":_vm.isMenuOpen('/')}},_vm._l((_vm.menuUsuario),function(items,index){return _c('div',{key:items.id,staticClass:"w-100"},[(items.submenus.length == 0)?_c('sidenav-router-link',{attrs:{"id":index,"icon":items.icon,"to":{ path: items.href },"exact":true}},[_vm._v(_vm._s(items.name))]):_vm._e(),_vm._v(" "),(items.submenus.length > 0)?_c('sidenav-menu',{attrs:{"icon":items.icon,"active":_vm.isMenuActive('/gestion-compras'),"open":_vm.isMenuOpen('/gestion-compras')}},[_c('template',{slot:"link-text"},[_vm._v(_vm._s(items.name))]),_vm._v(" "),_vm._l((items.submenus),function(item,index){return _c('sidenav-router-link',{key:item.id,staticClass:"inner_router-link",attrs:{"id":index,"icon":item.icon,"to":{ path: item.href },"exact":true}},[_vm._v(_vm._s(item.name))])})],2):_vm._e()],1)}),0),_vm._v(" "),_c('div',{staticClass:"subvencion",staticStyle:{"cursor":"pointer"}},[_c('a',{attrs:{"href":"https://oversea.netcomsi.es/images/subvencion_logos/logoSubvencion.JPG","target":"_blank"}},[_c('img',{staticClass:"p-1 mb-1",attrs:{"src":"/Images/oversea_logos/subvencion.jpg","width":"250","height":"175"}})])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }