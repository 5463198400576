const data = require("../../public/json/settings.json");
const API = data[0].dataUrl;
import axios from "axios";
// Guarda todas las localizaciones en localStorage
export function saveLanguages(data) {
  let language = data.language;
  //console.log("language", language);
  let asArray = Object.entries(data);
  asArray.forEach(keyValue => {
    let key = keyValue[0];
    let value = keyValue[1];
    if (key == "language") {
      return;
    }
    saveLanguage(key, value, language);
  });
}

// Obtiene una grupo de localizaciones de determinado idioma
export function getLanguageData(section, lang) {
  let needsLoad = false;
  let data = localStorage.getItem("l10n-" + section);
  // Comprobamos que los datos están en localStorage
  if (data != null) {
    data = JSON.parse(data);
    // Comprobamos que el idioma sea correcto
    if (data.language == undefined || data.language != lang) {
      localStorage.removeItem(section);
      needsLoad = true;
    }
  } else {
    data = getLanguageFromServer(section, lang);
    needsLoad = true;
  }
  // Si es necesario lo solicitamos del BackEnd, y lo guardamos
  if (needsLoad) {
    data = getLanguageFromServer(section, lang);
    saveLanguage(section, data, lang);
  }
  return data;
}

// Guarda un grupo de localizaciones en el localStorage
function saveLanguage(section, data, language) {
  data.language = language;
  //console.log("saveLanguage", data.language);
  localStorage.setItem("l10n-" + section, JSON.stringify(data));
}

function getLanguageFromServer(section, lang) {
  const token = localStorage.getItem("token");
  let result;
  let datos = {
    type: "getTitulos"
  };
  let config = {
    headers: {
      Authorization: "Bearer " + token,
      "Accept-Language": lang
    }
  };
  axios.post(API, datos, config).then(response => {
    saveLanguages(response.data.idiomaPantallas);
    result = response.data.idiomaPantallas;
  });
  console.log("🚀 ~ file: setLanguageJSONLocalstorage.js ~ line 74 ~ getLanguageFromServer ~ result[section]", result[section]);
  return result[section];
}

