<template>
  <div v-if="$route.path !== '/login'">
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
      <div
        class="
          container-fluid
          d-flex
          flex-wrap
          justify-content-between
          text-center
          container-p-x
          pb-1
        "
        style="background-color: rgba(0, 0, 0, 0.87); height: 0.8rem; font-size: 0.6rem; border-bottom: 1px solid black;"
      >
        <div></div>
        <div>
          <a class="footer-link mr-2 barra_ayuda" @click="modalOpenApp"
            >Acerca de la aplicacón</a
          >
          <a class="footer-link mr-2 barra_ayuda" @click="modalOpenView"
            >Acerca de la vista actual</a
          >
          <a class="footer-link mr-4 barra_ayuda">Ayuda</a>
          <Modal
            :open="isOpen"
            v-on:modalState="modalState"
            :dataContent="modalData"
          />
        </div>
      </div>
      <div
        class="
          container-fluid
          d-flex
          flex-wrap
          justify-content-between
          text-center
          container-p-x
          pb-1
        "
        style="background-image:url('/Images/SILICON_logos/0.png'); !important; height: 3rem; font-size: 0.8rem; "
      >
        <div class="pt-1 mt-3" style="font-size: 0.6rem;">
          Diseñado por
          <a
            href="https://www.siliconvalleyvigo.com"
            target="_blank"
            title="www.siliconvalleyvigo.com"
          >
            <img
              src="/Images/SILICON_logos/logoSVVApaisadoBlanco.svg"
              weight="33"
              height="28"
              class="ml-1 mr-1"
              style="margin-top: -0.8rem;"
            />
          </a>
          © 2023
        </div>
        <div>
          <a href="https://www.oversea.es/#" target="_blank" class="footer-link pt-3"
            >Sobre nosotros</a
          >
          <a href="https://www.oversea.es/aviso-legal/" target="_blank" class="footer-link pt-3 ml-4"
            >Información legal</a
          >
          <a href="https://www.oversea.es/contacto/" target="_blank" class="footer-link pt-3 ml-4"
            >Contacto</a
          >
          <a href="https://www.oversea.es/docs/privacidad.pdf" target="_blank" class="footer-link pt-3 ml-4"
            >Política de privacidad</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import Modal from "../components/_comunes/Modal.vue";
export default {
  name: "app-layout-footer",
  components: {
    Modal
  },
  props: {
    modalDataView: {
      type: Object
    }
  },
  data() {
    return {
      isOpen: false,
      modalData: {}
    };
  },
  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    },
    // apertura del modal de descripción de la app
    modalOpenApp() {
      this.modalData = {
        header: "<div class='descripcion-app col-lg-12 text-white'>Aplicación de gestión y control de Oversea Atlantic Fish, S.L.</div>",
        content: "<div class='dialog-content-app'><div class='msg-wrapper col-lg-12'>" +
          "</div><div class='descripcion-app col-lg-12'><span>Descripción de funcionalidades y características generales." +
          "</span> </div>" +
          "</div><div class='descripcion-app col-lg-12'><span><strong>Barra de navegación superior:</strong>" +
          "</span></div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Botón que oculta / muestra la barra lateral de menús.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Información de usuario conectado.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Botón 'Cerrar Sesión' que desconecta al usuario y vuelve a la pantalla de Login.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Fecha actual.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Botones que muestran banderas que identifican los idiomas disponibles en la aplicación. Clickando en ellos se cambia al &nbsp;&nbsp;deseado.</span>" +
          "</div>" +
          "</div>" +
          "</div><div class='descripcion-app col-lg-12'><span><strong>Barra de navegación Lateral: </strong>Menú de opciones de la aplicación, variará según los permisos del usuario." +
          "</span></div>" +
          "</div><div class='descripcion-app col-lg-12'><span><strong>Barra inferior: </strong>Información de uso y legal." +
          "</span></div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Acerca de la aplicación: abre ventana con info general sobre la aplicación.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Acerca de la vista actual: abre ventana que describe las opciones y funcionalidades de la vista en la que se encuentre el &nbsp;&nbsp;usuario.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Ayuda: inicializa el bot de ayuda, WairBot.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Sobre nosotros: link a nuestra página web.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Información legal: Aviso legal en cumplimiento de la Ley 34/2002.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Contacto: link a formulario de contacto.</span>" +
          "</div>" +
          "</div>" +
          "<div class='row'>" +
          "<div class='col-12'>" +
          "<span class='detalle-funcionalidades-app col-12'>- Política de privacidad: Confidencialidad y seguridad de los datos.</span>" +
          "</div>" +
          "</div>"
      };
      this.isOpen = true;
    },
    // apertura del modal de descripción de la vista en la que se esté navegando
    modalOpenView() {
      this.modalData = this.modalDataView;
      this.isOpen = true;
    },
    modalState(datos) {
      if (datos == true) {
        this.modalData = {};
        this.isOpen = false;
      }
    }
  }
};
</script>
<style>
.barra_ayuda {
  cursor: pointer !important;
  color: bisque !important;
}
.barra_ayuda:hover {
  color: rgb(223, 19, 19) !important;
}
/* modal info app */
/* textos de cabecera y descriptivos de la app en el cuerpo del modal (negros) */
.descripcion-app {
  position: relative;
  left: 5px;
  margin: 10px 0px 21px;
}
/* textos descriptivos de funcionalidades a 12 columnas */
span.detalle-funcionalidades-app {
  color: #66afe9;
  display: inline-block;
  position: relative;
  padding: 0px 16px 8px;
  top: -0.7rem;
  left: 2rem;
}
</style>
