<template>
  <div
    v-if="
      $route.path !== '/login' &&
        $route.path !== '/detalle-proveedor' &&
        $route.path !== '/detalle-cliente' &&
        $route.path !== '/detalle-familia' &&
        $route.path !== '/detalle-referencia' &&
        $route.path !== '/detalle-pedido' &&
        isDataReady
    "
  >
    <sidenav :orientation="orientation" :class="curClasses">
      <!-- Inner -->
      <div
        class="sidenav-inner w-100"
        :class="{ 'py-1': orientation !== 'horizontal' }"
        :active="isMenuActive('/')"
        :open="isMenuOpen('/')"
      >
        <div
          class="w-100"
          v-for="(items, index) in menuUsuario"
          :key="items.id"
        >
          <sidenav-router-link
            v-if="items.submenus.length == 0"
            :id="index"
            :icon="items.icon"
            :to="{ path: items.href }"
            :exact="true"
            >{{ items.name }}</sidenav-router-link
          >
          <sidenav-menu
            v-if="items.submenus.length > 0"
            :icon="items.icon"
            :active="isMenuActive('/gestion-compras')"
            :open="isMenuOpen('/gestion-compras')"
          >
            <template slot="link-text">{{ items.name }}</template>

            <sidenav-router-link
              v-for="(item, index) in items.submenus"
              :key="item.id"
              :id="index"
              :icon="item.icon"
              :to="{ path: item.href }"
              :exact="true"
              class="inner_router-link"
              >{{ item.name }}</sidenav-router-link
            >
          </sidenav-menu>
        </div>
      </div>
      <div class="subvencion" style="cursor:pointer;">
        <a href="https://oversea.netcomsi.es/images/subvencion_logos/logoSubvencion.JPG" target="_blank">
        <img
          src="/Images/oversea_logos/subvencion.jpg"
          width="250"
          height="175"
          class="p-1 mb-1"
        />
      </a>
      </div>
    </sidenav>
  </div>
</template>
<style>
.sidenav-link {
  color: white !important;
}
.sidenav-item:hover {
  background-color: rgba(172, 175, 228, 0.5) !important;
}
.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
  background-color: rgba(127, 133, 219, 0.5) !important;
}
</style>

<script>
import { getLanguageData } from "../utilidades/setLanguageJSONLocalStorage.js";
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    orientation: {
      type: String,
      default: "vertical"
    },
    idioma: {
      type: String
    }
  },
  data() {
    return {
      isDataReady: false,
      language: null,
      menuUsuario: null
    };
  },
  watch: {
    // se escucha el cambio de idioma cuando se hace click en una bandera y se trae desde localStorage
    // el menu en el nuevo idioma seleccionado.
    idioma: function(newValue) {
      if (newValue) {
        this.isDataReady = false;
        this.menuUsuario = getLanguageData("menus", newValue).menuUsuario;
        this.isDataReady = true;
      }
    }
  },
  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    }
  },
  async created() {
    // se trae el lenguage y el menu desde localStorage
    this.language = localStorage.getItem("language");
    this.menuUsuario = getLanguageData("menus", this.language).menuUsuario;
    //this.menuUsuario = getLanguageData("menu", this.language);
    this.isDataReady = true;
  }
};
</script>
