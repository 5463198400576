<template>
  <div>
    <div
      id="modal"
      class="control-section; position:relative"
      style="height:350px;"
    >
      <!-- Render modal Dialog -->
      <ejs-dialog
        ref="modalDialog"
        :isModal="isModal"
        :allowDragging="draggable"
        :header="dataContent.header"
        :target="target"
        :width="width"
        :visible='visible'
        :animationSettings="animationSettings"
        :content="dataContent.content"
        :overlayClick="overlayClick"
      >
      </ejs-dialog>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";
Vue.use(DialogPlugin);

export default {
  name: "ModalComponent",
  props: {
    open: {
      type: Boolean
    },
    dataContent: {
      type: Object
    }
  },
  data: function() {
    return {
      target: "#layout",
      width: "800px",
      draggable: true,
      isModal: true,
      visible: false,
      closeOnEscape: true,
      animationSettings: { effect: "FlipXDown" }
    };
  },
  watch: {
    open: function(newValue) {
      if (newValue == true) {
        setTimeout(() => {
          this.$refs.modalDialog.show();
        }, 400);
      }
    }
  },
  methods: {
    overlayClick: function() {
      this.$refs.modalDialog.hide();
      let modalClosed = true;
      this.$emit("modalState", modalClosed);
    }
  }
};
</script>
<style>
@import "../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
.control-section {
  height: 100%;
  min-height: 200px;
}
/* HEADER */
.e-dialog .e-dlg-header {
  color: white;
  font-size: 20px;
  font-weight: normal;
  width: 90% !important;
}
.e-dialog,
.e-dialog .e-dlg-header-content {
  background-color:#2E323A;
}
/* CONTENT */
.e-dialog .e-dlg-content {
  color: black;
  font-size: 10px;
  font-weight: normal;
  line-height: normal;
  margin-top: 1rem;
  padding: 30px 0px 0px !important;
}
/* BACKGROUND DETRAS DEL MODAL */
.e-dlg-overlay {
  background-color: slategray;
  opacity: 0.6;
}
</style>
