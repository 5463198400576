import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

import globals from "@/globals";

// Layouts
import Layout1 from "@/layout/Layout1";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: "",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: Layout1,
      children: [
        {
          path: "login",
          component: () => import("@/components/Login")
        },
        {
          path: "restablecer-password",
          component: () => import("@/components/RestablecerPassword")
        },
        {
          path: "crear-password",
          component: () => import("@/components/CrearPassword")
        },
        {
          path: "intrastat",
          component: () => import("@/components/IntrastatEspana")
        },
        {
          path: "gastos-pedidos",
          component: () => import("@/components/GastosPedidosCompra")
        },
        /* Vista apartado Gestión usuarios */
        {
          path: "gestion-usuarios",
          name: "GestionUsuarios",
          component: () => import("@/components/vistas/usuarios/Usuarios")
        },
        /* Alta de usuarios, click en el botón + de la tabla de Gestión usuarios */
        {
          path: "form-usuarios",
          name: "FormUsuarios",
          component: () => import("@/components/vistas/usuarios/FormUsuarios")
        },
        /* Vista apartado Gestión proveedores */
        {
          path: "gestion-proveedores",
          name: "GestionProveedores",
          component: () => import("@/components/vistas/proveedores/Proveedores")
        },
        /* Alta o edición de proveedores, click en el botón + de la tabla de Gestión proveedores */
        {
          path: "form-proveedores",
          name: "FormProveedores",
          component: () => import("@/components/vistas/proveedores/FormProveedores")
        },
        /* Detalle de proveedores, doble click en una fila de la tabla de Gestión proveedores */
        {
          path: "detalle-proveedor",
          name: "DetalleProveedor",
          component: () => import("@/components/vistas/proveedores/DetalleProveedor")
        },
        /* Vista apartado Gestión clientes */
        {
          path: "gestion-clientes",
          name: "GestionClientes",
          component: () => import("@/components/vistas/clientes/Clientes")
        },
        /* Alta de clientes, click en el botón + de la tabla de Gestión clientes */
        {
          path: "form-clientes",
          name: "FormClientes",
          component: () => import("@/components/vistas/clientes/FormClientes")
        },
        /* Detalle de clientes, doble click en una fila de la tabla de Gestión clientes */
        {
          path: "detalle-cliente",
          name: "DetalleCliente",
          component: () => import("@/components/vistas/clientes/DetalleCliente")
        },
        /* Vista apartado Gestión productos */
        {
          path: "gestion-productos",
          name: "GestionProductos",
          component: () => import("@/components/vistas/productos/Productos")
        },
        /* Alta o edición de familias y productos, click en el botón + de la tabla de Gestión productos */
        {
          path: "form-productos",
          name: "FormFamiliasYProductos",
          component: () => import("@/components/vistas/productos/FormFamiliasYProductos")
        },
        /* Detalle de familia, doble click en una fila de la tabla de Gestión productos */
        {
          path: "detalle-familia",
          name: "DetalleFamilia",
          component: () => import("@/components/vistas/productos/DetalleFamilia")
        },
        /* Detalle de referencia, doble click en una fila de la subtabla de Gestión productos */
        {
          path: "detalle-referencia",
          name: "DetalleReferencia",
          component: () => import("@/components/vistas/productos/DetalleReferencia")
        },
        /* Vista apartado Existencias / almacén */
        {
          path: "consulta-existencias-almacen",
          name: "ExistenciasPorAlmacen",
          component: () => import("@/components/vistas/almacen/ExistenciasPorAlmacen")
        },
        /* Vista apartado Gestión Compras */
        {
          path: "gestion-compras",
          name: "GestionCompras",
          component: () => import("@/components/vistas/compras/Compras")
        },
        /* Formulario de alta de pedidos, clcik en el botón + de la tabla de Gestión Compras */
        {
          path: "form-pedidos",
          name: "FormPedidos",
          component: () => import("@/components/vistas/compras/FormPedidos")
        },
        /* Formulario de edición de pedidos, doble click en una fila de la tabla de Gestión Compras */
        {
          path: "detalle-pedido",
          name: "DetalleCompra",
          component: () => import("@/components/vistas/compras/DetalleCompra")
        },
      ]
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // On small screens collapse sidenav
  if (
    window.layoutHelpers &&
    window.layoutHelpers.isSmallScreen() &&
    !window.layoutHelpers.isCollapsed()
  ) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10);
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0);
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
